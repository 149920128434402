import { COUNTRIES } from "../../../../constants/countries.c";
import {
  COMPONENT,
  CUSTOM_REGEX,
  FORM_FIELD_NAMES,
  VALIDATOR_NAME,
} from "../../../../constants/form-field-names";
import styles from "../../../../Home.module.css";
import validatorTypes from "@data-driven-forms/react-form-renderer/validator-types";
import { generateDateArray, getNewListTranslatedCountriesOnTop, translateCountries } from "../../../../utils/helper";
import { countriesValueToBeShownOnTop } from "../../../../constants/common.c";

export const commonSchema = (modelType: any, isJapaneseLanguage = false) => {

  const countriesList = isJapaneseLanguage ? getNewListTranslatedCountriesOnTop(COUNTRIES, countriesValueToBeShownOnTop) : translateCountries(COUNTRIES);

  const monthOptions = generateDateArray();
  return [
    {
      component: COMPONENT.DAS_FORM_SPY,
      name: FORM_FIELD_NAMES.LISTENER,
      hideField: false,
      className: styles.formLabelggg,
    },
    {
      component: COMPONENT.DAS_FORM_LABEL,
      name: FORM_FIELD_NAMES.ENTER_AMOUNT,
      label: "merchant.Payment_Information.Card_Details",
      className: "formLabel",
      rules: ["infoText.name"],
    },
    {
      component: COMPONENT.DAS_TEXT_FIELD,
      name: FORM_FIELD_NAMES.FIRST_NAME,
      placeholder: modelType && modelType === 1 ? "merchant.Payment_Information.fields.FirstName_For_Ibec" : "merchant.Payment_Information.fields.FirstName",
      isRequired: true,
      inputLabel: modelType && modelType === 1 ? "merchant.Payment_Information.fields.Name_on_Card_For_Ibec" : "merchant.Payment_Information.fields.Name_on_Card",
      // FormFieldGridProps: { xs: 6 },
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: CUSTOM_REGEX.name,
        },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 20,
        },
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      className: "iw-50",
    },
    {
      component: COMPONENT.DAS_TEXT_FIELD,
      name: FORM_FIELD_NAMES.LAST_NAME,
      placeholder: modelType && modelType === 1 ? "merchant.Payment_Information.fields.LastName_For_Ibec" : "merchant.Payment_Information.fields.LastName",
      isRequired: true,
      className: "iw-50",
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: CUSTOM_REGEX.name,
        },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 20,
        },
      ],
    },
    {
      component: COMPONENT.DAS_MASKED_TEXT_FILED,
      name: FORM_FIELD_NAMES.CARD_NUMBER,
      placeholder: "xxxx xxxx xxxx xxxx",
      isRequired: true,
      inputLabel: "merchant.Payment_Information.fields.CardNumber",
      className: "iw-100",
      // mask: [/[1-9]/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/,/\d/],
      validate: [
        // min length is checked in card validator so commenting it now
        // {
        //   type: validatorTypes.MIN_LENGTH,
        //   threshold: 17,
        // },
        {
          type: validatorTypes.REQUIRED,
        },
        { type: VALIDATOR_NAME.CARD_VALIDATOR },
      ],
    },
    {
      component: COMPONENT.DAS_MASKED_TEXT_FILED,
      name: FORM_FIELD_NAMES.CVV,
      placeholder: "xxx",
      // type: "number",
      isRequired: true,
      className: "iw-50",
      inputLabel: "merchant.Payment_Information.fields.CVV",
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
        {
          type: validatorTypes.PATTERN,
          pattern: /^[0-9]{3,4}$/,
        },
      ],
    },
    {
      component: isJapaneseLanguage ? COMPONENT.DAS_SELECT_OPTIONS : COMPONENT.DAS_MASKED_TEXT_FILED,
      options: monthOptions,
      name: "monthyear",
      isSearchable: false,
      placeholder: "merchant.Payment_Information.fields.monthyear",
      isRequired: true,
      type: isJapaneseLanguage ? "select" : "tel",
      className: isJapaneseLanguage ? "iw-50 month-year-select" : "iw-50 month-year-input",
      mask: [/[0-9]/, /\d/, "/", /\d/, /\d/],
      inputLabel: "merchant.Payment_Information.fields.Card_Expiry",
      validate: isJapaneseLanguage ? [
        {
          type: validatorTypes.REQUIRED,
        }
      ] : [
        {
          type: validatorTypes.REQUIRED,
        },
        { type: VALIDATOR_NAME.DATE_VALIDATOR },
      ],
    },
    {
      component: COMPONENT.DAS_FORM_LABEL,
      name: FORM_FIELD_NAMES.BILLING_INFORMATION,
      label: "merchant.Billing_Information.label",
      className: "formLabel",
      rules: [
        "infoText.email",
        "infoText.special",
        "infoText.phone",
        "infoText.address",
        "infoText.city",
        "infoText.postalCode",
        "infoText.memo",
      ],
    },
    {
      component: COMPONENT.DAS_TEXT_FIELD,
      name: FORM_FIELD_NAMES.EMAIL,
      placeholder: "merchant.Billing_Information.fields.EmailAddress",
      isRequired: true,
      inputLabel: "merchant.Billing_Information.fields.EmailAddress",
      className: "iw-50",
      validate: [
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 45,
        },
        {
          type: validatorTypes.REQUIRED,
        },
        {
          type: validatorTypes.PATTERN,
          pattern: "^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$",
        },
      ],
    },
    {
      component: COMPONENT.DAS_TEXT_FIELD,
      name: FORM_FIELD_NAMES.PHONE,
      placeholder: "merchant.Billing_Information.fields.Phone",
      isRequired: true,
      inputLabel: "merchant.Billing_Information.fields.Phone",
      className: "iw-50",
      validate: [
        {
          type: validatorTypes.PATTERN,
          pattern: "^[a-zA-Z0-9 _]*$",
        },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 15,
        },
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      component: COMPONENT.DAS_SELECT_OPTIONS,
      name: FORM_FIELD_NAMES.COUNTRY,
      type: "select",
      isRequired: true,
      placeholder: "merchant.Billing_Information.fields.Select",
      options: countriesList,
      inputLabel: "merchant.Billing_Information.fields.Country",
      className: "iw-50",
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },

    {
      component: COMPONENT.DAS_TEXT_FIELD,
      name: FORM_FIELD_NAMES.POSTAL_CODE,
      placeholder: modelType && modelType === 1 ? "merchant.Billing_Information.fields.Postal_Code_Placeholder_For_Ibec" : "merchant.Billing_Information.fields.Postal_Code_Placeholder",
      isRequired: true,
      className: "iw-100",
      inputLabel: modelType && modelType === 1 ? "merchant.Billing_Information.fields.Postal_Code_Label_For_Ibec" : "merchant.Billing_Information.fields.Postal_Code_Label",
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
        {
          type: validatorTypes.PATTERN,
          pattern: "^[a-zA-Z0-9 ]*$",
        },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 10,
        },
      ],
    },

    {
      component: COMPONENT.DAS_TEXT_FIELD,
      name: FORM_FIELD_NAMES.ADDRESSLINEONE,
      placeholder: modelType && modelType === 1 ? "merchant.Billing_Information.fields.BuildingNameStreetForIBEC" : "merchant.Billing_Information.fields.State",
      isRequired: true,
      inputLabel: "merchant.Billing_Information.fields.Address",
      className: "iw-50",
      validate: [
        // {
        //   type: validatorTypes.PATTERN,
        //   pattern: "^[a-zA-Z0-9 _]*$",
        // },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 199,
        },
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      component: COMPONENT.DAS_TEXT_FIELD,
      name: FORM_FIELD_NAMES.CITY,
      placeholder: modelType && modelType === 1 ? "merchant.Billing_Information.fields.CityStateForIBEC": "merchant.Billing_Information.fields.CityState",
      isRequired: true,
      className: "iw-50",
      inputLabel: "merchant.Billing_Information.fields.CityStateLabel",
      validate: [
        // {
        //   type: validatorTypes.PATTERN,
        //   pattern: "^[a-zA-Z0-9 _]*$",
        // },
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 20,
        },
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      component: COMPONENT.DAS_TEXT_FIELD,
      name: FORM_FIELD_NAMES.MEMO,
      inputLabel: "merchant.Payment_Information.fields.Memo",
      placeholder: "merchant.Payment_Information.fields.MemoPlaceholder",
      isRequired: false,
      classN: "iw-100",
      validate: [
        {
          type: validatorTypes.MAX_LENGTH,
          threshold: 255,
        }
      ],
    },
    {
      component: COMPONENT.TEXT_FIELD,
      name: FORM_FIELD_NAMES.HAS_VISA,
      hideField: false,
    },
    {
      component: COMPONENT.TEXT_FIELD,
      name: FORM_FIELD_NAMES.HAS_MASTER_CARD,
      hideField: false,
    },
    {
      component: COMPONENT.TEXT_FIELD,
      name: FORM_FIELD_NAMES.HAS_JCB,
      hideField: false,
    },
    {
      component: COMPONENT.TEXT_FIELD,
      name: FORM_FIELD_NAMES.HAS_AMEX,
      hideField: false,
    },
    {
      component: COMPONENT.TEXT_FIELD,
      name: FORM_FIELD_NAMES.HAS_DINERSCLUB,
      hideField: false,
    },

  ]
};
