import React from "react";

import Details from "../details/Details";
import ApplePayButton from "../apple-pay-button/ApplePayButton";
import DasCustomGpayButton from "../google-pay-button/DasCustomGpayButton";
import { DasGooglePayButton } from "../google-pay-button/DasGooglePayButton";
import { useNavigate } from "react-router-dom";
import DasORSeparator from "../apple-pay-button/components/DasORSeparator";
import HppWrapper from "./HppWrapper";
import { applePayController } from "../apple-pay-button/components/apple-pay";

const LangWrapper = ({
  isIbec = false,
  apiData,
  children,
  pblProduct,
  subscriptionProduct,
  setApiData,
  setAmountPopup,
  handleApplePayClick,
  slug,
  setDasLoader,
}: any) => {
  const { address: ADDRESS, company: COMPANY } = apiData;
  const navigate = useNavigate();

  const GpayApplePayAlignment = isIbec && applePayController.isAppleSessionAvailable() && apiData?.hasApplePay && apiData?.hasGooglePay
    ? "vertical-alignment" : "";

  return (
    <HppWrapper apiData={apiData}>
      {/*Product details */}
      <div className="flex-section">
        <div className="additional-product">
          {subscriptionProduct && subscriptionProduct}
          {pblProduct && pblProduct}
        </div>
        <div className="form-div">
          <Details
            COMPANY={COMPANY || ""}
            ADDRESS={ADDRESS || ""}
            // CURRENCY={CURRENCY || ""}
            // amountLabel={amount || 0}
          />
          {/* Pay Buttons */}
          <div
            className={

              apiData?.product?.length > 0
                ? `wallet-buttons main-sep pbl-sep ${GpayApplePayAlignment}`
                : `wallet-buttons main-sep ${GpayApplePayAlignment}`
            }
          >
            <div className="wallet-apple-google-pay-buttons">
              {/* this is -----Apple Pay Button------ */}
              {apiData?.hasApplePay ? (
                <ApplePayButton
                  apiData={apiData}
                  setApiData={setApiData}
                  setAmountPopup={setAmountPopup}
                  handleApplePayClick={handleApplePayClick}
                />
              ) : (
                ""
              )}

              {/* this is -----Google Pay Button------ */}
              {apiData?.hasGooglePay ? (
                <>
                  {!apiData?.amount ? (
                    <DasCustomGpayButton
                      slug={slug}
                      apiData={apiData}
                      setAmountPopup={setAmountPopup}
                    />
                  ) : (
                    <>
                      <DasGooglePayButton
                        apiData={apiData}
                        slug={slug}
                        navigate={navigate}
                        setDasLoader={setDasLoader}
                      />
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
            {(apiData?.hasApplePay || apiData?.hasGooglePay) && (
              <DasORSeparator />
            )}
          </div>

          {children}
        </div>
      </div>

      {/* subscription details */}
    </HppWrapper>
  );
};

export default LangWrapper;
