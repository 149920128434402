import "./style.css";
import LangWrapper from "./LangWrapper";
import SubscriptionDetails from "../subscription-details/SubscriptionDetails";
import ProductView from "../product-view/ProductView";

const MainWrapper = ({
  isIbec,
  children,
  apiData,
  setApiData,
  setAmountPopup,
  handleApplePayClick,
  slug,
  setDasLoader,
  productList,
}: any) => {
  // console.log(productList, "productList"); 
  return (
    <>
      <div className="wrappers">
        <LangWrapper
          isIbec={isIbec}
          apiData={apiData}
          setApiData={setApiData}
          setAmountPopup={setAmountPopup}
          handleApplePayClick={handleApplePayClick}
          slug={slug}
          setDasLoader={setDasLoader}
          subscriptionProduct={
            apiData?.subscription && (
              <>
                <SubscriptionDetails
                  amount={apiData?.subscription?.amount}
                  ccy={apiData?.subscription?.ccy}
                  billingCycleType={apiData?.subscription?.billingCycleType}
                  planName={apiData?.subscription?.planName}
                  subscriptionEndsAt={apiData?.subscription?.subscriptionEndsAt}
                  subscriptionSource={apiData?.subscription?.subscriptionSource}
                  subscriptionStartsAt={
                    apiData?.subscription?.subscriptionStartsAt
                  }
                  subscriptionType={apiData?.subscription?.subscriptionType}
                  trialPeriodDuration={
                    apiData?.subscription?.trialPeriodDuration
                  }
                  trialPeriodDurationType={
                    apiData?.subscription?.trialPeriodDurationType
                  }
                  planDiscountPercentage={
                    apiData?.subscription?.planDiscountPercentage
                  }
                  planDiscountDuration={
                    apiData?.subscription?.planDiscountDuration
                  }
                />
              </>
            )
          }
          pblProduct={
            <>
              <ProductView
                amountLabel={apiData?.amount}
                currency={apiData?.currency}
                productList={productList}
                entity={apiData?.entity}
              />
            </>
          }
        >
          <div>
            {/* other payment methods (hiding them for now) */}
            {/* <OtherPaymentMethods /> */}
          </div>
          {children}
        </LangWrapper>
      </div>
    </>
  );
};

export default MainWrapper;
