import React from 'react'
import { useTranslation } from 'react-i18next';

const DasORSeparator = () => {
    const { t } = useTranslation();
    return (
        <div className='pay-seperator' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <hr style={{ width: "45%", opacity: 0.3 }} />
            <p style={{ width: "35%", textAlign: "center", fontSize: "13px" }}>
                {t("Or Pay With Card")}
            </p>
            <hr style={{ width: "45%", opacity: 0.3 }} />
        </div>
    )
}

export default DasORSeparator;
